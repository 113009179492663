const state = function () {
	return {
		token: null,
		refreshToken: null,
		tokenRequestTime: null,
		verified: false,
		checkoutToken: null,
		checkoutRefreshToken: null,
		checkoutTokenRequestTime: null,
		checkoutVerified: false,
		bloqueioDisciplinas: [],
		permiteVerModalTermos: false,
	};
};

export default state;
