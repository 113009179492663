<template>
	<div class="mb-6" id="header-info">
		<div class="title-bar columns">
			<div class="column is-6 pb-0 mb-0">
				<h2 class="title is-1 mx-5 mt-1">
					<slot />
				</h2>
			</div>

			<div
				v-if="hasManyCourses"
				class="column is-6 pb-0 mb-0"
			>
				<FiltroVinculo />
			</div>
		</div>

		<div class="title-id mx-5">
			<span class="mr-6">{{ nomeLabel }}: <strong>{{ nome }}</strong></span>

			&nbsp;<br class="is-hidden-tablet">

			<span>Matrícula: <strong>{{ matricula }}</strong></span>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FiltroVinculo from '@components/FiltroVinculo';

export default {
	name: 'Title',

	components: {
		FiltroVinculo
	},

	computed: {
		...mapState({
			matricula: state => state.alunoMatricula,
		}),

		...mapGetters('cursos', [
			'getCursos'
		]),

		...mapGetters('aluno/dadosPessoais', [
			'nome',
			'temNomeSocial',
		]),

		hasManyCourses() {
			return this.getCursos.length > 1;
		},

		nomeLabel() {
			return this.temNomeSocial
				? 'Nome Social'
				: 'Nome';
		},
	}
}
</script>

<style lang="scss" scoped>
.title-bar {
  line-height: 57px;
}

h2.title {
  margin: auto 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 36px;
  line-height: 40px;
  font-weight: 900;
}

hr {
  height: 2px;
  margin: 5px 0 16px;
  background-color: #d5d6d8;
}

.title-id {
	span {
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
	}
}

.title-id strong {
  color: #173048;
}
</style>
