import http from '@commons/http';

const actions = {
	// confirmar se vamos usar o checkoutToken ou o token
	cadastrarSenha: async (_, { alun_token, senha, captcha }) => {
		const params = new URLSearchParams({ alun_token, senha, captcha });
		const response = await http.post(`/v1/matricula/senha?${params.toString()}`, null, {}, 'json', true);
		return response.status === 200 ? response.data : null;
  },

	gerarCodigo: async (_, { cpf, captcha }) => {
		const params = new URLSearchParams({ cpf: cpf.replace(/[^a-zA-Z0-9]/g, ''), captcha });
		const response = await http.post(`/v1/auth/senha/otp?${params.toString()}`, null, {}, 'json', true);
		return response.status === 200 ? response.data : null;
  },

	validarCodigo: async ({ commit }, { cpf, captcha, codigo }) => {
		const params = new URLSearchParams({
			cpf: cpf.replace(/[^a-zA-Z0-9]/g, ''),
			captcha,
			codigo
		});
		const response = await http.post(`/v1/auth/senha/otp/valida?${params.toString()}`, null, {}, 'json', true);


		commit('sessao/setCheckoutToken', {
			token: response.data.token,
			refreshToken: response.data.refreshToken,
		}, { root: true });

		return response.status === 200;
  },

	recuperarCandidato: async (_, { cand_token, alun_token }) => {
		const params = new URLSearchParams({
			...cand_token && { cand_token },
			...alun_token && { alun_token },
		});
		const response = await http.get(`/v1/inscricao/candidato?${params.toString()}`, null, {}, 'json', true);
		return response.status === 200 ? response.data : null;
  },

	baixarPdf: async (_, { alun_token, doc_id }) => {
		const params = new URLSearchParams({
			doc_id,
		});
		const customeHeaders = {
			'X-TOKEN': alun_token,
		}
		const response = await http.get(`/v1/aluno/pagamentos/boleto-pdf?${params.toString()}`, {}, customeHeaders, 'blob');
		return response.status === 200 ? response.data : null;
  },

	buscarCursosAdquiridos: async () => {
		const response = await http.get('/v1/inscricao/cursos-adquiridos', null, {}, 'json', true);
		return response.status === 200 ? response.data : null;
  },

	getCurso: async (_, { curs_id, nens_id }) => {
		const params = new URLSearchParams({ curs_id, nens_id });
		const response = await http.get(`/v1/inscricao/curso?${params.toString()}`, null, {}, 'json', true);
		return response.status === 200 ? response.data : null;
  },

  getEndereco: async (_, cep) => {
		const response = await http.get(`/v1/aluno/cep-address?cep=${cep}`, null, {}, 'json', true);
		return response.status === 200 ? response.data : null;
  },

  inscrever: async (_, payload) => {
		const canal = 'web';
		const {
			dados_pessoais: { cpf, email, name, phone, social_name },
			endereco: { cida_id, esta_id, zipcode, endereco, bairro, number, complement },
			endereco_cobranca = {},
			responsavel_financeiro = {},
			nens_id,
			curs_id,
		} = payload;

		const queryParams = new URLSearchParams({
			nens_id,
			curs_id,
			canal,
			nome: name,
			telefone: phone,
			cpf,
			email,
			esta_id,
			cida_id,
			cep: zipcode,
			end: endereco,
			end_bairro: bairro,
			end_numero: number === null ? '' : number,
			end_compl: complement === null ? '' : complement,
			...(social_name && { nome_social: social_name }),
			...(!responsavel_financeiro.cnpj && endereco_cobranca.endereco && { end_cobranca: endereco_cobranca.endereco }),
			...(!responsavel_financeiro.cnpj && endereco_cobranca.complement && { end_compl_cobranca: endereco_cobranca.complement }),
			...(!responsavel_financeiro.cnpj && endereco_cobranca.number && { end_numero_cobranca: endereco_cobranca.number }),
			...(!responsavel_financeiro.cnpj && endereco_cobranca.bairro && { end_bairro_cobranca: endereco_cobranca.bairro }),
			...(!responsavel_financeiro.cnpj && endereco_cobranca.cida_id && { cida_id_cobranca: endereco_cobranca.cida_id }),
			...(!responsavel_financeiro.cnpj && endereco_cobranca.zipcode && { cep_cobranca: endereco_cobranca.zipcode }),
			...(responsavel_financeiro.cnpj && endereco_cobranca.endereco && { end_responsavel: endereco_cobranca.endereco }),
			...(responsavel_financeiro.cnpj && endereco_cobranca.complement && { end_compl_responsavel: endereco_cobranca.complement }),
			...(responsavel_financeiro.cnpj && endereco_cobranca.number && { end_numero_responsavel: endereco_cobranca.number }),
			...(responsavel_financeiro.cnpj && endereco_cobranca.bairro && { end_bairro_responsavel: endereco_cobranca.bairro }),
			...(responsavel_financeiro.cnpj && endereco_cobranca.cida_id && { cida_id_responsavel: endereco_cobranca.cida_id }),
			...(responsavel_financeiro.cnpj && endereco_cobranca.zipcode && { cep_responsavel: endereco_cobranca.zipcode }),
			...(responsavel_financeiro.company_name && { razao_social_responsavel: responsavel_financeiro.company_name }),
			...(responsavel_financeiro.cnpj && { cnpj_responsavel: responsavel_financeiro.cnpj }),
			...(responsavel_financeiro.email && { email_responsavel: responsavel_financeiro.email }),
			...(responsavel_financeiro.municipal_registration && {
				inscricao_municipal_responsavel: responsavel_financeiro.municipal_registration,
			}),
			...(responsavel_financeiro.state_registration && {
				inscricao_estadual_responsavel: responsavel_financeiro.state_registration,
			}),
			...(responsavel_financeiro.tax_regime && { regime_fiscal_responsavel: responsavel_financeiro.tax_regime.toUpperCase().replace(' ', '_') }),
			...(responsavel_financeiro.cnpj && {
				substituto_issqn_responsavel: responsavel_financeiro.issqn ? 'T' : 'F',
			}),
		});

		const response = await http.post(`/v1/academico/inscricao?${queryParams.toString()}`, null, {}, 'json', true);
		return response.status === 200 ? response.data : null;
  },

  getPagamentos: async (_, { cupom, cand_id }) => {
		const params = new URLSearchParams({ cand_id, ...(cupom && { cupom }) });
		const response = await http.get(`/v1/financeiro/preco/checkout?${params.toString()}`, null, {}, 'json', true);
		return response.status === 200 ? response.data : null;
  },
	getContrato: async (_, payload) => {
		const {
			cand_id,
			parcelas,
			cupom,
			oppa_id,
		} = payload;
		const params = new URLSearchParams({
			cand_id,
			...(oppa_id && { oppa_id }),
			...(parcelas && { parcelas }),
			...(cupom && { cupom }),
		});
		const response = await http.post(`/v1/matricula/oferta?${params.toString()}`, null, {}, 'json', true);
		return response.status === 200 ? response.data : null;
	},
	validaCupom: async (_, { cand_id, cupom }) => {
		const params = new URLSearchParams({ cand_id, cupom });
		const response = await http.get(`/v1/academico/codigo-promocional?${params.toString()}`, null, {}, 'json', true);
		return response.status === 200 ? response.data : null;
	},
	matricular: async (_, payload) => {
		const {
			cand_id,
			oppa_id,
			parcelas,
			dia_vencimento,
			cupom,
			dados_cartao = {},
		} = payload;
		const {
			bandeira, cvv, nome_cartao: nome, numero_cartao: num_cartao, validade
		} = dados_cartao;

		const queryParams = new URLSearchParams({
			cand_id,
			parcelas,
			...(oppa_id && { oppa_id }),
			...(dia_vencimento && { dia_vencimento }),
			...(cupom && { cupom }),
			...(bandeira && { bandeira }),
			...(cvv && { cvv }),
			...(validade && { validade }),
			...(nome && { nome }),
			...(num_cartao && { num_cartao }),
		});

		const response = await http.post(`/v1/matricula/matricula?${queryParams.toString()}`, null, {}, 'json', true);
		return response.status === 200 ? response.data : null;
	},
};

export default actions;
