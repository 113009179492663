<template>
	<div :data-theme="dark || ie === 'ebr' ? 'dark' : 'light'" ref="grecaptcha"></div>
</template>

<script>
import { getDynamicIeBySubDomain } from '@commons/helpers';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default {
	name: 'Recaptcha',
	props: {
		dark: {
			type: Boolean,
			required: false,
			default: false
		},
	},
	data() {
		return {
			ie: '',
			resolve: null,
			reject: null,
			recaptchaId: null,
		};
	},
	mounted() {
		const iesList = require('../ies/ies.list.json');
		this.ie = getDynamicIeBySubDomain(window.location.hostname, iesList);

		let recaptchaScript = document.createElement('script');

		recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
		recaptchaScript.setAttribute('async', '');
		recaptchaScript.addEventListener('load', this.generateRecaptchaId);
		document.head.appendChild(recaptchaScript);
	},
	methods: {

		async ready() {
			for (;;) {
				if (this.recaptchaId !== null) {
					return true;
				}

				await sleep(10);
			}
		},

		async generate() {
			await this.ready();

			window.grecaptcha.execute(this.recaptchaId);

			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		getToken(token) {
			window.grecaptcha.reset(this.recaptchaId);
			this.resolve(token);

		},
		getError() {
			this.reject();
		},
		generateRecaptchaId() {
			const options = {
				sitekey: '6LfYeMAZAAAAAN8KU5gANTzCmgenyUX4yrdUqi7j',
				size: 'invisible',
				callback: this.getToken,
				'error-callback': this.getError
			};

			window.grecaptcha.ready(() => {
				this.recaptchaId = window.grecaptcha.render(this.$refs.grecaptcha, options);
			});
		},

	}
}
</script>
