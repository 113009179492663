import axios from 'axios';
import vuex from '../store/store';
import router from '../router/router';
import {
	getDynamicIeBySubDomain,
	changeAPIEnvironment
} from '@commons/helpers';

const iesList = require('../ies/ies.list.json');

let api_config_json = null;

try {
	let ie = getDynamicIeBySubDomain(window.location.hostname, iesList);
	api_config_json = require(`../ies/${ie}.config.json`);
} catch (error) {
	api_config_json = require('../ies/default.config.json');
}

let apiChanged = changeAPIEnvironment(window.location);

const api_path = apiChanged ? apiChanged : api_config_json.api_path;
const x_apikey = api_config_json.x_apikey;
const x_apikey_checkout = api_config_json.x_apikey_checkout;

const makeFullURL = url => {
	if (window.location.href.includes('?debug')) {
		url = url + '?debug';
	}
	return url.match(RegExp('https?://')) || url.match(RegExp('.php'))
		? url
		: api_path + url;
};

const http = {
	get(url, data, customHeaders = {}, responseType = 'json', isCheckout = false) {
		let defaultHeaders = {
			'Content-Type': 'application/x-www-form-urlencoded',
			'X-APIKEY': isCheckout ? x_apikey_checkout : x_apikey,
			'X-TOKEN': isCheckout ? vuex.state.sessao.checkoutToken : vuex.state.sessao.token,
		};

		Object.assign(defaultHeaders, customHeaders);

		let fullUrl = makeFullURL(url);

		return new Promise((resolve, reject) => {
			axios
				.get(fullUrl, {
					params: data,
					headers: defaultHeaders,
					responseType: responseType
				})
				.then(resolve)
				.catch(xhr => reject(xhr.response));
		});
	},
	post(url, data, customHeaders = {}, responseType = 'json', isCheckout = false) {
		let defaultHeaders = {
			'Content-Type': 'application/x-www-form-urlencoded',
			'X-APIKEY': isCheckout ? x_apikey_checkout : x_apikey,
			'X-TOKEN': isCheckout ? vuex.state.sessao.checkoutToken : vuex.state.sessao.token,
		};

		Object.assign(defaultHeaders, customHeaders);

		let fullUrl = makeFullURL(url);

		return new Promise((resolve, reject) => {
			axios
				.post(fullUrl, data, {
					headers: defaultHeaders,
					responseType: responseType
				})
				.then(resolve)
				.catch(xhr => reject(xhr.response));
		});
	},
	put(url, data, customHeaders = {}, responseType = 'json', isCheckout = false) {
		let defaultHeaders = {
			'Content-Type': 'application/x-www-form-urlencoded',
			'X-APIKEY': isCheckout ? x_apikey_checkout : x_apikey,
			'X-TOKEN': isCheckout ? vuex.state.sessao.checkoutToken : vuex.state.sessao.token,
		};

		Object.assign(defaultHeaders, customHeaders);

		let fullUrl = makeFullURL(url);

		return new Promise((resolve, reject) => {
			axios
				.put(fullUrl, data, {
					headers: defaultHeaders,
					responseType: responseType
				})
				.then(resolve)
				.catch(xhr => reject(xhr.response));
		});
	}
};

// Handle 401 ou token expirado
axios.interceptors.response.use(
	response => {
		return response;
	},
	async error => {
		// let data = {
		// 	data: JSON.stringify(error.response)
		// };

		// data = new URLSearchParams(Object.entries(data)).toString();

		// await http.post('/v1/auth/salva-log', data);
		if (!error.config.__isRetry) error.config.__isRetry = 0;
		if (
			[400, 401].includes(error.response.status) &&
			error.response.data.message === 'Token não autorizado![37]' &&
			error.config.__isRetry < 3
		) {
			error.config.__isRetry++;

			if (error.config.url.includes('/refresh')) {
				router.push({ name: 'login.home' });
				return Promise.reject(error);
			}

			try {
				await vuex.dispatch('sessao/refreshToken');
			} catch (_) {
				// ignore
			}

			if (!vuex.getters['sessao/isValidToken']) {
				router.push({ name: 'login.home' });
				return Promise.reject(error);
			}

			error.config.headers['X-TOKEN'] = vuex.state.sessao.token;
			return axios.request(error.config);
		}
		return Promise.reject(error);
	}
);

/** sum two values and return */

export default http;
