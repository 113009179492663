<template>
    <div class="ebradi-header-curso">
        <div class="ebradi-header-curso-title">
            <img src="@ebradi/assets/img/logo.svg">
            <h2>
                {{ titleCurso }}
            </h2>
        </div>
        <div
            class="ebradi-header-curso-steps"
        >
            <div
                v-for="(item, num) in steps"
                :key="num"
                class="step"
                :class="{ 'active': (step > item.step), 'local': (step < item.step) }"
            >
                <span class="step-ball">{{ item.step }}</span>
                <span class="step-label">{{ item.label }}</span>
            </div>
            <div class="step-separator" />
        </div>
    </div>
</template>

<script>

export default {
    name: 'HeaderCursoComponetEBRADI',
    props: {
        titleCurso: {
            type: String,
            required: true,
            default: ''
        },
        step: {
            type: Number,
            required: true,
            default: 1,
            validator: function (value) {
                return [1, 2, 3].includes(value)
            }
        }
    },
    data() {
        return {
            steps: [
                { step: 1, label: 'Cadastro' },
                { step: 2, label: 'Pagamento e Contrato' }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.ebradi-header-curso {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 42px;
    padding-left: 82px;
    padding-right: 82px;

    &-title {
        display: flex;
        align-items: center;

        img {
            width: 56px;
            height: 100%;
        }

        h2 {
            max-width: 622px;
            width: 100%;
            font-size: 24px;
            line-height: 32px;
            font-weight: 900;
            color: var(--secondary-400);
            border-left: 2px solid var(--secondary-400);
            border-radius: 6px;
            padding-left: 26px;
            margin-left: 16px;
        }
    }

    &-steps {
        display: flex;
        position: relative;

        .step {
            margin-left: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 1;

            &:first-child {
                margin-left: 0px;
            }

            &.local {
                .step-ball {
                    background: var(--charcoal-gray);
                    border-color: #777;
                    color: #777;
                }

                .step-label {
                    color: #777;
                }
            }

            &.active {
                .step-ball {
                    background: var(--primary);
                    color: var(--charcoal-gray);
                }

                .step-label {
                    color: var(--primary);
                }
            }

            &-label {
                display: block;
                font-size: 12px;
                line-height: 16px;
                font-weight: 700;
                text-align: center;
                color: var(--turquoise-blue);
            }

            &-ball {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                margin-bottom: 8px;
                border-radius: 16px;
                color: var(--turquoise-blue);
                border: 1px solid var(--turquoise-blue);
                background: var(--charcoal-gray);
            }
        }

        .step-separator {
            position: absolute;
            height: 2px;
            background-color: var(--turquoise-blue);
            width: calc(100% - 80px);
            left: 20px;
            top: 15px;
        }
    }
}


@media screen and (max-width: 900px)  {
	.ebradi-header-curso {
        padding-left: 28px;
        padding-right: 28px;
        padding-top: 36px;


        .ebradi-header-curso-title {
            h2 {
                margin-left: 0px;
                font-size: 14px;
                line-height: 20px;
            }
            
            img {
                display: none;
            }
        }

        .ebradi-header-curso-steps {
            display: none;
        }
	}
}
</style>
