import { isObjectLike } from 'lodash';
import http from '@commons/http';

const actions = {
	aceitarTermosFiltro: async ({ commit }) => {
		commit('loading', true);

		try {
			await http.post('/v1/aluno/aceite-filtro-vinculo');
		} catch (error) {
			console.error('Ocorreu um erro ao aceitar os termos do vínculo.');
		} finally {
			commit('setAceiteTermosFiltro', true);
			commit('loading', false)
		}
	},

	setVinculoSelecionado: ({ state, dispatch, commit }, vinculo) => {
		if (isObjectLike(vinculo) && isObjectLike(state.vinculoSelecionado)) {
			if (state.vinculoSelecionado.viac_id === vinculo.viac_id) {
				return;
			}
		}

		if (!isObjectLike(vinculo) && !isObjectLike(state.vinculoSelecionado)) {
			if (state.vinculoSelecionado.toLowerCase() === vinculo.toLowerCase()) {
				return;
			}
		}

		commit('setVinculoSelecionado', vinculo);
		commit('setNivelDeEnsino', vinculo.nens_id, { root: true });

		dispatch('pagamentos/todosPagamentos', null, { root: true });
		dispatch('documentos/todosDocumentos', null, { root: true });
		dispatch('servicos/todosServicos', null, { root: true });
	}
};

export default actions;
