<template>
	<header>
		<img 
			src="@ebradi/assets/img/arrow-left.svg"
			@click="goBack"
		>
		<div>
			<img src="@ebradi/assets/img/logo-semifull.svg">
		</div>
	</header>
</template>

<script>
export default {
	name: 'HeaderMobileComponentEBRADI',
	methods: {
		goBack() {
			console.log(1)
		}
	}
}
</script>

<style lang="scss" scoped>
header {
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 42px 0;
	display: none;

	& > img {
		&:first-child {
			cursor: pointer;
			transition: 0.4s;
			margin-left: 22px;

			&:hover {
				opacity: 0.6;
			}
		}
	}

	& > div {
		margin: 0 auto;

	}
}

@media screen and (max-width: 900px)  {
	header {
		display: flex;
	}
}

</style>