<script>
import Header from '@components/Header';
import DocumentsTerm from '@components/DocumentsTerm';
import ChatMenu from '@components/ChatMenu';
import PopupNotification from '@components/notification/PopupNotification';
import AlertNotification from '@components/notification/AlertNotification';
import { get } from 'lodash';
import { mapGetters } from 'vuex';

export default {
	name: 'Home',
	components: {
		Header,
		ChatMenu,
		PopupNotification,
		AlertNotification,
	},
	data() {
		return {
			isServiceOnline: false,
			isDocumentTermModalOpened: false,
		};
	},
	computed: {
		showInterface() {
			return (
				this.$store.state.aceiteTermoDocumentos || !this.permiteVerModalTermos
			);
		},
		isHome() {
			return this.$route.path == '/';
		},
		getPopupNotification() {
			return this.hasPopupNotification
				? this.$store.state.popupNotifications[0]
				: false;
		},
		getAlertNotification() {
			return this.hasAlertNotification
				? this.$store.state.alertNotifications[0]
				: false;
		},
		hasPopupNotification() {
			return this.$store.state.popupNotifications.length > 0;
		},
		hasAlertNotification() {
			return this.$store.state.alertNotifications.length > 0;
		},
		...mapGetters({
			isVerified: 'sessao/isVerified',
			permiteVerModalTermos: 'sessao/getPermiteVerModalTermos'
		}),
		hasToast() {
			return this.$store.getters['ui/toast/hasToast'];
		}
	},
	watch: {
		hasToast: function(hasToast) {
			if (hasToast) {
				this.openToast();
			}
		},
		isVerified() {
			this.openDocumentTermModal();
		},
	},
	async created() {
		// if (!this.$store.state.calouro) {
		// 	return;
		// }

		this.openDocumentTermModal();
	},
	methods: {
		get,
		openDocumentTermModal() {
			if (
				!this.permiteVerModalTermos ||
				this.isDocumentTermModalOpened
				|| !this.isVerified
				|| this.$store.state.aceiteTermoDocumentos
			) {
				return;
			}

			this.isDocumentTermModalOpened = true;

			this.$buefy.modal.open({
				parent: this,
				component: DocumentsTerm,
				hasModalCard: true,
				canCancel: false,
				events: {
					'close': () => {
						this.isDocumentTermModalOpened = false;
					},
				},
			});
		},
		async openToast() {
			const defaultProps = {
				duration: 4000
			};

			while (this.$store.getters['ui/toast/hasToast']) {
				const toast = this.$store.getters['ui/toast/getFirst'];

				const wasRemoved = this.$store.dispatch('ui/toast/remoteFirst');

				this.$buefy.toast.open({ ...defaultProps, ...toast });

				await wasRemoved;
			}
		},
	},
};
</script>

<template>
	<div class="page home">
		<Header v-show="showInterface && !hasPopupNotification" />
		<div class="section">
			<div class="container">
				<router-view v-show="showInterface && !hasPopupNotification" />
			</div>
		</div>

		<PopupNotification
			v-if="hasPopupNotification && showInterface"
			:notification="getPopupNotification"
		/>

		<AlertNotification
			v-if="
				isHome && !hasPopupNotification && hasAlertNotification && showInterface
			"
			:notification="getAlertNotification"
		/>

		<ChatMenu v-if="showInterface && !hasPopupNotification" />

		<b-loading
			v-model="$store.getters['ui/isLoading']"
			:is-full-page="true"
			:can-cancel="false"
		/>
	</div>
</template>

<style lang="scss" scoped>
@import '@styles/_variables';

.page {
	background-color: $gray-20;
	background-color: var(--gray-20);
}
</style>
