<template>
	<section class="container">
		<Card background="block" :loading="loading">
			<template #header>
				<HeaderCurso
					:title-curso="curso"
					:step="step"
				/>
			</template>
			<div class="ebradi-card-body">
				<HeaderInfo
					:image="image"
					:title="title"
					:sub-title="header_info.sub_title"
				/>
				<div class="box-resumo">
					<Card
						bars
						background="blur"
					>
						<template #header>
							<div class="card-custom-margin">
								<h2>{{ curso.title }}</h2>
								<h5><b>Forma de pagamento:</b> {{ valorPagamento }}</h5>
							</div>
						</template>
						<div class="flex-card is-flex flex-wrap is-justify-content-space-between card-custom-margin">
							<div>
								<b>Nome completo:</b> {{ nome }}<br>
								<b>Telefone:</b> {{ telefone }}<br>
								<b>Email:</b> {{ email }}<br>
								<b>CPF:</b> {{ cpf }}<br>
								<b>Endereço de cobrança:</b> {{ endereco }}<br>
							</div>
							<Price
								:price="de"
								:special-price="por"
							/>
						</div>
					</Card>
					<div
						v-show="loadingCartao"
						class="text-loading"
					>
						<div class="loader_c" />
						Aguardando confirmação {{ formaPagamento.match(/Gratuito|Boleto|Pix/i) ? 'da matrícula' : 'do pagamento' }}...
					</div>
					<div
						v-show="successCartao"
						class="text-pagament0-concluido"
					>
						<img src="@ebradi/assets/img/credit-card.svg">
						Pagamento concluído via cartão de crédito!
					</div>
					<div v-show="showBoleto">
						<div class="bar-code">
							<div>
								<b-button
									type="is-secondary"
									rounded
									class="btn-custom-bar"
									:outlined="!codigoCopiado"
									@click="copiarCodigo"
								>
									{{ !codigoCopiado ? 'Copiar código' : 'Código copiado!' }}
									<img src="@ebradi/assets/img/icon-3.svg" >
								</b-button>
								<span>
									Vencimento: {{ vencimento }}
								</span>
								<span>
									*A compensação será feita em até 3 dias úteis.
								</span>
							</div>
							<div>
								<svg id="barcode"></svg>
								<div class="icons-bar">
									<span v-show="!canShare" class="loader_b ml-2" />
									<img v-show="docPrepared" class="doc-prepared" src="@ebradi/assets/img/icon-1.svg" @click="baixarPdf">
									<img v-show="canShare" class="doc-prepared" src="@ebradi/assets/img/icon-2.svg" @click="compartilhar">
								</div>
							</div>
						</div>
					</div>
					<div class="text-center-primary" v-show="showBoleto">
						Você receberá um e-mail após a confirmação do pagamento com as instruções de acesso.
					</div>
				</div>
			</div>
		</Card>
		<Modal v-model="alertCartao" color="danger">
			<div class="card-danger-cartao">
				<div
					class="time"
					@click="() => alertCartao = false"
				>
					X
				</div>
				<img src="@ebradi/assets/img/wallet-danger.svg">
				<h2>Falha no pagamento</h2>
				<p>
					O pagamento não pôde ser processado. Tente novamente ou escolha outro método de pagamento para concluir sua matrícula.
				</p>
				<b-button
					type="is-secondary"
					rounded
					outlined
					@click="voltarPagamento"
				>
					Voltar
				</b-button>
			</div>
		</Modal>
		<LoadingPage v-model="loading" />
	</section>
</template>

<script>
import moment from 'moment';
import JsBarcode from 'jsbarcode';
import Card from '@ebradi/componets/Card';
import HeaderCurso from '@ebradi/componets/HeaderCurso';
import HeaderInfo from '@ebradi/componets/HeaderInfo';
import LoadingPage from '@ebradi/componets/LoadingPage';
import Price from '@ebradi/componets/Price';
import Modal from '@ebradi/componets/Modal';

import { MetodoPagamento } from '@enum/checkout';

import { capitalizeIgnoringArticles, currencyFormatter, phoneFormatter } from '@commons/helpers';

const TIMER_POOLING = 15000;
const TIMER_SUCCESS = 5000;
const REVOKE_DELAY = 7000;

export default {
	name: 'ResumoPageEBRADI',
	components: {
		Card,
		HeaderCurso,
		HeaderInfo,
		LoadingPage,
		Price,
		Modal
	},
	data() {
		return {
			step: 3,
			loading: false,
			alunToken: this.$route.query.alun_token,
			loadingCartao: false,
			successCartao: false,
			showBoleto: false,
			codigoCopiado: false,
			alertCartao: false,
			candidato: {},
			header_info: {
				title: 'Resumo',
				sub_title: 'Concluindo esta etapa você terá acesso ao portal',
				image: require('@ebradi/assets/img/image-5.svg')
			},
			docPrepared: false,
			docError: false,
			canShare: false,
			doc: null,
		}
	},
	computed: {
		formaPagamento() {
			return this.$route.params.forma_pagamento
				|| this.candidato.forma_pagamento
				|| ''
			;
		},

		image() {
			return this.formaPagamento !== 'Gratuito' ? require('@ebradi/assets/img/image-1.svg') : require('@ebradi/assets/img/image-5.svg');
		},

		title() {
			return this.formaPagamento !== 'Gratuito' ? 'Resumo da compra' : 'Resumo';
		},

		valorPagamento() {
			if (!this.formaPagamento) {
				return '';
			}

			if (this.$route.params.descricao_valor) {
				return `${this.formaPagamento} em ${this.$route.params.descricao_valor}`;
			}

			if (this.formaPagamento === 'Gratuito') {
				return this.formaPagamento;
			}

			return `${this.candidato.forma_pagamento} em ${this.candidato.numero_parcelas}X (${currencyFormatter(this.candidato.valor_com_desconto)})`
		},

		de() {
			if (this.$route.params.valor_original) {
				const valorOriginal = parseFloat(this.$route.params.valor_original);

				if (!valorOriginal) {
					return null;
				}

				return currencyFormatter(this.$route.params.valor_original);
			}

			if (!this.candidato.valor_original) {
				return null;
			}
			if (!this.candidato.numero_parcelas) {
				return currencyFormatter(this.candidato.valor_original);
			}
			return currencyFormatter(this.candidato.valor_original * this.candidato.numero_parcelas);
		},

		por() {
			if (this.$route.params.valor_final) {
				return currencyFormatter(this.$route.params.valor_final);
			}

			if (!this.candidato.valor_com_desconto) {
				return null;
			}
			if (!this.candidato.numero_parcelas) {
				return currencyFormatter(this.candidato.valor_com_desconto);
			}
			return currencyFormatter(this.candidato.valor_com_desconto * this.candidato.numero_parcelas);
		},

		nome() {
			return this.$route.params.cand_nome_social
				|| this.$route.params.cand_nome
				|| this.candidato.cand_nome_social
				|| this.candidato.cand_nome
			;
		},

		telefone() {
			if (this.$route.params.cand_fone_celular) {
				return this.$route.params.cand_fone_celular;
			}
			if (this.candidato.cand_fone_celular) {
				return phoneFormatter(this.candidato.cand_fone_celular);
			}
			return '';
		},

		email() {
			return this.$route.params.cand_email
				|| this.candidato.cand_email
			;
		},

		cpf() {
			if (this.$route.params.cand_cpf) {
				return this.$route.params.cand_cpf;
			}
			if (this.candidato.cand_cpf) {
				return this.candidato.cand_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
			}
			return '';
		},

		vencimento() {
			return moment(this.candidato.vencimento).format('DD/MM/YYYY');
		},

		curso () {
			if (this.$route.params.curso_nome) {
				return capitalizeIgnoringArticles(this.$route.params.curso_nome);
			}
			if (this.candidato.curso) {
				return capitalizeIgnoringArticles(this.candidato.curso);
			}
			return '';
		},

		endereco () {
			if (this.$route.params.endereco) {
				return this.$route.params.endereco;
			}
			if (this.candidato.endereco) {
				return this.candidato.endereco;
			}
			return '';
		},
	},
	async mounted() {
		if (this.$route.params.aguardar) {
			this.loadingCartao = true;

			try {
				await this.$route.params.aguardar;
			} catch (error) {
				console.error(error);
			}
			// finally {
			// 	this.loadingCartao = false;
			// }
		} else {
			this.loading = true;
		}

		this.recoverData();
	},
	methods: {
		async recoverData() {
			await this.fetchData()
				.then(async (res) => {
					if ('Compra Não Finalizada' === res.status) {
						this.$router.push({
							name: 'venda.assistida',
							query: { token: res.cand_token },
						});
						this.loading = false;
						return;
					}

					this.candidato = res;
					this.loading = false;

					if (res.status === 'Falha no Pagamento') {
						this.alertCartao = true;
						return;
					}

					if (res.status === 'Compra Aprovada') {
						this.alunToken = res.alun_token;
						this.loadingCartao = true;
						setTimeout(() => {
							if (res.forma_pagamento === 'Gratuito') {
								if (res.usua_forca_alterar_senha === 't') {
									this.$router.replace({
										name: 'parabens',
										query: {
											alun_token: res.alun_token,
											nome: res.cand_nome_social || res.cand_nome,
										},
									})
								} else {
									location.href = '/';
								}
							} else {
								this.loadingCartao = false;
								this.successCartao = true;
								setTimeout(() => {
									if (res.usua_forca_alterar_senha === 't') {
										this.$router.replace({
											name: 'parabens',
											query: {
												alun_token: res.alun_token,
												nome: res.cand_nome_social || res.cand_nome,
											},
										})
									} else {
										location.href = '/';
									}
								}, TIMER_SUCCESS);
							}
						}, TIMER_POOLING);
						return;
					}

					if ([
						MetodoPagamento.CARTAO_DE_CREDITO_A_VISTA,
						MetodoPagamento.CARTAO_DE_CREDITO_RECORRENTE
					].includes(res.oppa_id)) {
						this.loadingCartao = true;
						setTimeout(() => this.pooling(), TIMER_POOLING);
					} else if ([
						MetodoPagamento.BOLETO_A_VISTA,
						MetodoPagamento.BOLETO_RECORRENTE,
					].includes(res.oppa_id)) {
						this.loadingCartao = false;
						this.$store.dispatch('ebradi/baixarPdf', {
							alun_token: res.alun_token,
							doc_id: res.doc_id,
						})
							.then(async (res) => {
								const blob = new Blob([res], { type: 'application/pdf' });
								this.doc = blob;
								this.docPrepared = true;
							}).catch(() => {
								this.docError = true;
							}).finally(() => {
								this.canShare = true;
							});
						this.showBoleto = true;
						this.$nextTick(() => {
							JsBarcode('#barcode', res.codigo_barras, {
								format: "CODE128",
								lineColor: '#191d21',
								background: "#D1D1D1",
								height: 80,
								displayValue: false,
								marginLeft: 15,
								marginRight: 15,
								marginTop: 0.01,
								marginBottom: 0.01
							});
						});
					}
				})
				.catch(() => {
					this.toastOpen('Erro ao recuperar dados do candidato', 'is-danger');
					this.loading;
					return;
				})
				.finally(() => this.loading = false)
		},
		async pooling() {
			await this.fetchData()
				.then(async (res) => {
					if (res.status === 'Compra Aprovada') {
						this.alunToken = res.alun_token;
						this.loadingCartao = false;
						this.successCartao = true;
						setTimeout(() => {
							if (res.usua_forca_alterar_senha === 't') {
								this.$router.replace({
									name: 'parabens',
									query: {
										alun_token: res.alun_token,
										nome: res.cand_nome_social || res.cand_nome,
									},
								})
							} else {
								location.href = '/';
							}
						}, TIMER_SUCCESS);
						return;
					} else if (res.status === 'Falha no Pagamento') {
						this.loadingCartao = false;
						this.alertCartao = true;
						return;
					}

					setTimeout(() => this.pooling(), TIMER_POOLING);
				})
				.catch(() => {
					this.toastOpen('Erro ao recuperar dados da compra', 'is-danger');
					return;
				})
		},

		copiarCodigo() {
			navigator.clipboard.writeText(this.candidato.linha_digitavel).then(
				() => {
					this.codigoCopiado = true;
				},
				() => {}
			);
		},

		baixarPdf() {
			if (!this.docPrepared) return;
			const link = document.createElement('a');
			link.href = URL.createObjectURL(this.doc);
			link.download = 'Boleto.pdf';
			link.click();
			link.remove();
			setTimeout(() => URL.revokeObjectURL(link.href), REVOKE_DELAY);
		},

		async fetchData() {
			const queryParams = [
				'alun_token',
				'cand_token',
			];

			const payload = {};

			for (const queryParam of queryParams) {
				if (this.$route.query[queryParam]) {
					payload[queryParam] = this.$route.query[queryParam];

					break;
				}
			}

			if (Object.keys(payload).length === 0) {
				return Promise.reject();
			}

			return this.$store.dispatch('ebradi/recuperarCandidato', payload);
		},

		compartilhar() {
			if (this.docPrepared) {
				try {
					const file = new Blob([this.doc], { type: 'application/pdf' });
					const filesArray = [new File([file], 'boleto.pdf', { type: 'application/pdf' })];

					if (navigator.canShare) {
						if (navigator.canShare({ files: filesArray })) {
							navigator.share({
								files: filesArray,
								title: 'Boleto',
								text: this.candidato.linha_digitavel,
							})
							.catch((error) => {
								console.error('Erro ao compartilhar:', error);
								this.toastOpen('Erro ao compartilhar boleto', 'is-danger');
							});
						}
					}
				} catch (error) {
					this.toastOpen('Erro ao compartilhar boleto', 'is-danger');
				}
			} else {
				navigator.share({
					title: 'Boleto',
					text: this.candidato.linha_digitavel,
				})
				.catch((error) => {
					console.error('Erro ao compartilhar:', error);
					this.toastOpen('Erro ao compartilhar boleto', 'is-danger');
				});
			}
		},

		voltarPagamento() {
			this.$router.push({
				name: 'venda.assistida',
				query: { token: this.candidato.cand_token },
			});
		},

		toastOpen(message, type) {
			this.$buefy.toast.open({
				message,
				type,
				duration: 4000,
			});
		},
	}
}
</script>

<style lang="scss" scoped>
svg#barcode {
	border-radius: 8px;
}
.ebradi-card-body {
	padding: 42px 82px;

	.btns {
		text-align: center;
		margin-top: 72px;
		margin-bottom: 22px;

		.btn-margin-right {
			margin-right: 32px;
		}
	}
}

.icons-bar {
	display: flex;
	width: 100%;
	gap: 25px;
	padding: 10px 10px 00px 0px;
	justify-content: flex-end;
	align-items: center;

	img.doc-prepared {
		cursor: pointer;
		transition: 0.4s;

		&:hover {
			opacity: 0.6;
		}
	}
}

.box-resumo {
	margin-top: 88px;
}

.card-custom-margin {
	padding: 32px 44px;

	h2 {
		color: var(--secondary-400);
		font-size: 18px;
		line-height: 24px;
		font-weight: 800;
	}

	&::v-deep {
		.component-price {
			min-width: 30%;

			.price-content {
				margin-left: auto;
				margin-right: 0;
			}
		}
	}

	&:last-child {
		padding-top: 0px;
	}
}

.text-center-primary {
	color: #BADFE7;
	margin-top: 42px;
	text-align: center;
	font-size: 12px;
	font-weight: 800;
	line-height: 24px;
}

.text-pagament0-concluido {
	margin-top: 42px;
	gap: 20px;
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	color: var(--secondary-400);
	display: flex;
	align-items: center;
	justify-content: center;
}

.bar-code {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 62px;

	& > div {
		margin-top: 20px;
	}

	#barcode {
		width: 100%;
	}

	.btn-custom-bar {
		margin-bottom: 20px;

		&::v-deep {
			span {
				display: flex;
				align-items: center;
				gap: 10px;
			}
		}
	}

	span {
		width: 100%;
		display: block;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;

		&:last-child {
			color: var(--secondary-400);
		}
	}
}

.text-loading {
	width: 100%;
	margin: 42px 0;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;

	font-size: 20px;
	font-weight: 500;
	line-height: 24px;

	.loader_c {
		width: 33px;
		padding: 3px;
		aspect-ratio: 1;
		border-radius: 50%;
		background: var(--secondary-400);
		--_m:
			conic-gradient(#0000 10%,#777), linear-gradient(#000 0 0) content-box;
		-webkit-mask: var(--_m);
				mask: var(--_m);
		-webkit-mask-composite: source-out;
				mask-composite: subtract;
		animation: l3 1s infinite linear;
	}
}

.card-danger-cartao {
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	& > .time {
		position: absolute;
		top: 20px;
		right: 40px;
		font-weight: 700;
		font-size: 16;
		cursor: pointer;
		transition: 0.4s;

		&:hover {
			color: var(--primary);
		}
	}

	h2 {
		font-size: 28px;
		font-weight: 900;
		line-height: 32px;
		font-family: 'Lato';
		margin-top: 22px;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #777;
		max-width: 500px;
		margin: 42px 0;
		text-align: center;
	}

	button {
		margin-bottom: 22px;
	}
}

@keyframes l3 {
	to {
		transform: rotate(1turn)
	}
}

@media screen and (max-width: 900px)  {
	.ebradi-card-body {
		padding: 21px 28px;
	}

	.flex-card {
		& > div:first-child {
			width: 100%;
		}
	}

	.card-custom-margin {
		padding: 16px 22px;
		font-size: 12px;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;

		&::v-deep {
			.component-price {
				margin-top: 20px;
			}
		}

		h2 {
			font-size: 12px;
		}
	}
}

@media screen and (max-width: 1084px)  {
	.bar-code {
		justify-content: center;
		flex-direction: column-reverse;

		& > div {
			&:first-child {
				text-align: center;
			}
		}
	}
}

.loader_b {
	width: 20px !important;
}
</style>
