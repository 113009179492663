<template>
	<div class="header-info">
		<img
			v-if="image.length >= 0"
			:src="image"
			class="image"
		>
		<div class="header-info-text">
			<h2>{{ title }}</h2>
			<h4>{{ subTitle }}</h4>
			<slot 
				name="info"
				:toggleModalLogin="toggleModalLogin"
			>
				{{ info }}
			</slot>
		</div>
		<ModalLogin v-model="modalLogin" />
	</div>
</template>

<script>
import ModalLogin from '@ebradi/componets/ModalLogin'

export default {
	name: 'HeaderInfoComponentEBRADI',
	components: {
		ModalLogin
	},
	props: {
		title: {
			type: String,
			required: false,
			default: ''
		},
		subTitle: {
			type: String,
			required: false,
			default: ''
		},
		info: {
			type: String,
			required: false,
			default: ''
		},
		image: {
			type: String,
			required: false,
			default: ''
		}
	},
	data() {
		return {
			modalLogin: false
		}
	},
	methods: {
		toggleModalLogin(value = null) {
			if (value === null) {
				this.modalLogin = !this.modalLogin
			} else {
				this.modalLogin = value
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.header-info {
	display: flex;
	align-items: center;

	.image {
		width: 130px;
		height: 131px;
	}

	&-text {
		margin-left: 24px;
		padding-bottom: 28px;

		h2 {
			color: var(--light-gray);
			font-weight: 800;
			font-size: 26px;
			line-height: 32px;
			margin-top: 35px;
		}

		h4 {
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: var(--medium-gray);
			margin-top: 10px;
		}

		h5 {
			color: var(--dark-gray);
			line-height: 20px;
			font-size: 14px;
			font-weight: 500;
			margin-top: 10px;
		}
	}
}

@media screen and (max-width: 900px)  {
	.header-info {
		.image {
			width: 75px;
			height: 75px;
		}

		&-text {
			h2 {
				font-size: 14px;
				margin-top: 28px;
				line-height: 16px;
			}

			h4 {
				font-size: 12px;
				margin-top: 0px;
			}

			h5 {
				font-size: 10px;
				margin-top: 6px;
			}
		}
	}
}
</style>
