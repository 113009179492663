var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form_dados_pessoais"},[_c('form',{staticClass:"form-dados-pessoais",on:{"submit":function () { return false; }}},[_c('h2',{staticClass:"titulo"},[_vm._v(" Dados pessoais ")]),(!_vm.view)?_c('fieldset',[_c('div',{staticClass:"colunas-inputs"},[_c('div',{staticClass:"coluna"},[_c('ValidationProvider',{attrs:{"name":"Nome","rules":"required|max:128|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Nome","message":(errors.length > 0) ? errors[0] : 'Nome como consta no seu documento de identidade',"type":errors.length > 0 ? 'is-danger' : ''}},[_c('b-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}],null,false,2993578274)})],1),_c('div',{staticClass:"coluna"},[_c('ValidationProvider',{attrs:{"name":"Telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Telefone","message":errors[0],"type":errors.length > 0 ? 'is-danger' : ''}},[_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) # ####-####']),expression:"['(##) ####-####', '(##) # ####-####']"}],attrs:{"type":"tel"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)]}}],null,false,3585895344)})],1),_c('div',{staticClass:"coluna"},[_c('ValidationProvider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"E-mail","message":errors[0],"type":errors.length > 0 ? 'is-danger' : ''}},[_c('b-input',{attrs:{"type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}],null,false,3428382963)})],1),_c('div',{staticClass:"coluna"},[_c('ValidationProvider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"CPF","message":errors[0],"type":errors.length > 0 ? 'is-danger' : ''}},[_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}})],1)]}}],null,false,3562007641)})],1),_c('div',{staticClass:"coluna"},[_c('b-field',{staticClass:"label-switch",class:{ 'input-social': !_vm.socialName }},[_c('label',{staticClass:"label"},[_vm._v(" Nome Social (Opcional) "),_c('b-switch',{staticClass:"label-input-switch",staticStyle:{"margin-left":"15px"},attrs:{"size":"is-small"},model:{value:(_vm.socialName),callback:function ($$v) {_vm.socialName=$$v},expression:"socialName"}})],1),(_vm.socialName)?_c('ValidationProvider',{attrs:{"name":"Nome Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{model:{value:(_vm.form.social_name),callback:function ($$v) {_vm.$set(_vm.form, "social_name", $$v)},expression:"form.social_name"}}),_c('p',{staticClass:"help",class:{ 'is-danger': (errors.length > 0) }},[_vm._v(" "+_vm._s((errors.length > 0) ? errors[0] : 'Nome pelo qual a pessoa se identifica e é socialmente reconhecido.')+" ")])]}}],null,false,890563116)}):_vm._e()],1)],1)])]):_c('Card',{staticClass:"view-card-info",attrs:{"bars":"","background":"blur","size":"normal"}},[_c('div',[(_vm.form.social_name !== null)?_c('p',[_c('b',[_vm._v("Nome Social:")]),_vm._v(" "+_vm._s(_vm.form.name)+" ")]):_vm._e(),_c('p',[_c('b',[_vm._v("Nome Completo:")]),_vm._v(" "+_vm._s(_vm.form.name)+" ")]),_c('p',[_c('b',[_vm._v("Telefone:")]),_vm._v(" "+_vm._s(_vm.form.phone)+" ")]),_c('p',[_c('b',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.form.email)+" ")]),_c('p',[_c('b',[_vm._v("Número da matrícula:")]),_vm._v(" "+_vm._s(_vm.form.matricula)+" ")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }