<template>
	<div class="container">
		<Card background="block" :loading="loading">
			<template #header>
				<HeaderNavbar
					class="header-cursos-adquiridos"
					:step="1"
				/>
			</template>
			<div class="ebradi-card-body">
				<HeaderInfo
					:image="require('@ebradi/assets/img/image-2.svg')"
					title="Cursos adquiridos"
					sub-title="Confira os cursos que comprou e seus respectivos status"
				>
					<template #info>
						<h5><a @click="logout">Sair</a></h5>
					</template>
				</HeaderInfo>
				<form onsubmit="return false" name="form-search-cursos" class="pt-6">
					<fieldset class="cards-cursos">
						<div>
							<b-field
								label="Curso"
							>
								<b-input
									type="text"
									v-model="filtro.titulo"
								>
								</b-input>
							</b-field>
						</div>
						<div>
							<b-field>
								<label class="label">
									Status
									<svg
										@click="() => this.statusInfo = !this.statusInfo"
										width="16"
										class="ml-2"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M7.1547 4.872V3.408H8.8347V4.872H7.1547ZM7.2507 12V6.132H8.7507V12H7.2507Z" fill="#9B7C57"/>
										<circle cx="8" cy="8" r="7.5" stroke="#9B7C57"/>
									</svg>
								</label>
								<b-select placeholder="Todos os status"  icon="" v-model="filtro.status">
									<option
										v-for="option in status"
										:value="option"
										:key="option.id">
										{{ option.status }}
									</option>
								</b-select>
							</b-field>
						</div>
					</fieldset>
				</form>
				<div
					class="ebradi-cards cards-cursos"
					:class="{'ebradi-cards-one': filtroCursos.length <= 1}"
				>
					<div
						v-for="(item, index) in filtroCursos"
						:key="index"
						class="column is-6 mt-4"
					>
						<Card
							:header="item.name"
							background="blur"
							bar
							size="normal"
							:color="(item.status === 'Compra Aprovada') ? 'primary' : 'secondary' "
						>
							<div class="ebradi-card-body-curso">
								Data: {{ new Date(item.data).toLocaleDateString() }}<br>
								Forma de pagamento: {{ item.forma_pagamento }}<br>
								Status: <b
									:class="{
										'has-text-danger': (item.status === 'Falha no Pagamento'),
										'has-text-secondary': (item.status === 'Compra Não Finalizada'),
										'has-text-primary': (item.status === 'Compra Aprovada')
									}"
								>
									{{ item.status }}
								</b>
							</div>
							<div class="is-flex is-justify-content-center is-align-content-center pb-4 pt-4">
								<b-button
									:type="typeBtn(item.status)"
									rounded
									:outlined="!['Compra Aprovada', 'Compra Não Finalizada'].includes(item.status)"
									@click="action(item)"
								>
									{{ labelBtn(item.status) }}
								</b-button>
							</div>
						</Card>
					</div>
				</div>
			</div>
		</Card>
		<Modal v-model="alertCartao" color="danger">
			<div class="card-danger-cartao">
				<div
					class="time"
					@click="() => alertCartao = false"
				>
					X
				</div>
				<img src="@ebradi/assets/img/wallet-danger.svg">
				<h2>Falha no pagamento</h2>
				<p>
					O pagamento não pôde ser processado. Tente novamente ou escolha outro método de pagamento para concluir sua matrícula.
				</p>
				<b-button
					type="is-secondary"
					rounded
					outlined
					@click="voltarPagamento"
				>
					Continuar
				</b-button>
			</div>
		</Modal>
		<ModalInfoStatus v-model="statusInfo" />
		<LoadingPage v-model="loading" />
	</div>
</template>

<script>
import Card from '@ebradi/componets/Card';
import HeaderInfo from '@ebradi/componets/HeaderInfo';
import HeaderNavbar from '@ebradi/componets/HeaderNavbar';
import LoadingPage from '@ebradi/componets/LoadingPage';
import ModalInfoStatus from '@ebradi/componets/ModalInfoStatus';
import Modal from '@ebradi/componets/Modal';
import { MetodoPagamento } from '@enum/checkout';

import { capitalizeIgnoringArticles } from '@commons/helpers';

export default {
	name: 'CursosAdquiridosPageEBRADI',
	components: {
		Card,
		HeaderNavbar,
		HeaderInfo,
		LoadingPage,
		ModalInfoStatus,
		Modal,
	},
	data() {
		return {
			candidato: {},
			alertCartao: false,
			loading: false,
			statusInfo: false,
			filtro: {
				titulo: '',
				status: { id: 0, status: 'Todos os status' },
			},
			status: [
				{ id: 0, status: 'Todos os status' },
				{ id: 2, status: 'Compra Aprovada' },
				{ id: 3, status: 'Falha no Pagamento' },
				{ id: 4, status: 'Compra Não Finalizada' },
				{ id: 5, status: 'Pagamento pendente' }
			],
			cursos: []
		}
	},
	mounted() {
		this.loading = true;

		this.$store
			.dispatch('ebradi/buscarCursosAdquiridos')
			.then(res => {

				this.cursos = res.map(item => ({
					name: capitalizeIgnoringArticles(item.curso),
					status: item.status,
					data: item.data,
					forma_pagamento: item.forma_pagamento,
					content: item,
				}));

				this.loading = false;
			})
			.catch(() => {
				this.toastOpen('Erro ao buscar cursos adquiridos', 'is-danger');
				this.loading = false;
			});
	},
	computed: {
		filtroCursos() {
			let filtrado = this.cursos;

			if (this.filtro.titulo.length) {
				const titulo = this.filtro.titulo.toLowerCase();
				filtrado = filtrado.filter(item => {
					const name = item.name.toLowerCase();
					return name.includes(titulo);
				});
			}

			if (this.filtro.status.id) {
				filtrado = filtrado.filter(item => {
					return item.status === this.filtro.status.status;
				});
			}

			return filtrado;
		},
	},
	methods: {
		action(item) {
			switch (item.status) {
				case 'Compra Aprovada':
					if (
						'Gratuito' === item.forma_pagamento ||
						[
							MetodoPagamento.BOLETO_A_VISTA,
							MetodoPagamento.BOLETO_RECORRENTE,
							MetodoPagamento.CARTAO_DE_CREDITO_A_VISTA,
							MetodoPagamento.CARTAO_DE_CREDITO_RECORRENTE,
						].includes(item.content.oppa_id)
					) {
						this.$store
							.dispatch('ebradi/recuperarCandidato', {
								alun_token: item.content.alun_token,
							})
							.then(res => {
								if (res.usua_forca_alterar_senha === 't') {
									const { href } = this.$router.resolve({
										name: 'parabens',
										query: {
											alun_token: item.content.alun_token,
											nome: item.content.cand_nome_social || item.content.cand_nome,
										},
									});
									window.open(href, '_blank');
								} else {
									const { href } = this.$router.resolve({ path: '/' });
									window.open(href, '_blank');
								}
							})
							.catch(() => {
								this.toastOpen('Erro ao acessar dados do curso', 'is-danger');
								this.loading = false;
							});
						break;
					}
					break;
				case 'Falha no Pagamento':
					this.alertCartao = true;
					this.candidato = item.content;
					break;
				case 'Pagamento pendente':
					this.$router.push({
						name: 'resumo',
						query: { alun_token: item.content.alun_token },
					});
					break;
				default:
					this.$router.push({
						name: 'venda.assistida',
						query: { token: item.content.cand_token },
					});
					break;
			}
		},
		voltarPagamento() {
			this.$router.push({
				name: 'venda.assistida',
				query: { token: this.candidato.cand_token },
			});
		},
		logout() {
			this.$store.dispatch('sessao/logoffCheckout')
				.then(() => {
					this.$router.push({ name: 'login.cpf' });
				});
		},
		labelBtn(status) {
			switch (status) {
				case 'Compra Aprovada':
					return 'Acessar curso'
				case 'Compra Não Finalizada':
					return 'Finalizar compra'
				default:
					return 'Ver detalhes'
			}
		},
		typeBtn(status) {
			switch (status) {
				case 'Compra Aprovada':
					return 'is-primary'
				default:
					return 'is-secondary'
			}
		},
		toastOpen(message, type) {
			this.$buefy.toast.open({
				message,
				type,
				duration: 4000,
			});
		},
	}
}
</script>

<style lang="scss" scoped>
.ebradi-card-body {
	padding: 42px 62px;
	padding-top: 62px;

	.btns {
		text-align: center;
		margin-top: 42px;
	}
}

.ebradi-card-body-curso {
	padding: 0 24px;
	padding-bottom: 34px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
}

.ebradi-cards {
	margin: 42px 0;
}

.has-text-secondary {
	color: var(--secondary-400);
}

.has-text-danger {
	color: #C93F58;
}

.cards-cursos {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	gap: 42px;
	margin-bottom: 32px!important;

	& > div {
		max-width: 460px;
		width: 100%;
	}
}

.card-danger-cartao {
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	& > .time {
		position: absolute;
		top: 20px;
		right: 40px;
		font-weight: 700;
		font-size: 16;
		cursor: pointer;
		transition: 0.4s;

		&:hover {
			color: var(--primary);
		}
	}

	h2 {
		font-size: 28px;
		font-weight: 900;
		line-height: 32px;
		font-family: 'Lato';
		margin-top: 22px;
	}

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #777;
		max-width: 500px;
		margin: 42px 0;
		text-align: center;
	}

	button {
		margin-bottom: 22px;
	}
}

div.ebradi-cards-one {
	justify-content: flex-start!important;
}

@media screen and (max-width: 900px)  {
	.ebradi-card-body {
		padding: 21px 28px;
	}

	.header-cursos-adquiridos {
		display: none;
	}
}
</style>
