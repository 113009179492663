const mutations = {
	setToken(state, { token, refreshToken }) {
		state.token = token;
		state.refreshToken = refreshToken;
		state.tokenRequestTime = new Date();
	},

	setVerified(state, isVerified) {
		state.verified = isVerified;
	},

	setCheckoutToken(state, { token, refreshToken }) {
		state.checkoutToken = token;
		state.checkoutRefreshToken = refreshToken;
		state.checkoutTokenRequestTime = new Date();
	},

	setCheckoutVerified(state, isVerified) {
		state.checkoutVerified = isVerified;
	},

	setBloqueioDisciplinas: (state,bloqueio) => {
		state.bloqueioDisciplinas = bloqueio;
	},

	setPermiteVerModalTermos: (state, permite) => {
		state.permiteVerModalTermos = permite;
	},
};

export default mutations;
