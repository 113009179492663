import { isPlainObject } from 'lodash';
import VueRouter from 'vue-router';

import {
	getDynamicIeBySubDomain
} from '@commons/helpers';

const iesList = require('../ies/ies.list.json');
const ie      = getDynamicIeBySubDomain(window.location.hostname, iesList);

let routerEBRADI = [];

if (ie === 'ebr') {
	routerEBRADI = [
		{
			path: '/',
			component: () => import('@ebradi/layouts/Default'),
			meta: { requiresCheckoutAuth: false },
			children: [
				{
					path: 'checkout/:nens_id/:curs_id',
					name: 'checkout',
					component: () => import('@ebradi/pages/Checkout'),
					meta: { requiresCheckoutAuth: false, header: false },
				},
				{
					path: 'checkout/assistido',
					name: 'venda.assistida',
					component: () => import('@ebradi/pages/Checkout'),
					meta: { requiresCheckoutAuth: false, header: false, assistido: true },
				},
				{
					path: 'cursos-adquiridos',
					name: 'cursos.adquiridos',
					component: () => import('@ebradi/pages/CursosAdquiridos'),
					meta: { requiresCheckoutAuth: true, header: false, redirect: 'login.cpf' },
				},
				{
					path: 'login-cpf',
					name: 'login.cpf',
					component: () => import('@ebradi/pages/Login'),
					meta: { requiresCheckoutAuth: false, header: true },
				},
				{
					path: 'parabens',
					name: 'parabens',
					component: () => import('@ebradi/pages/Parabens'),
					meta: { requiresCheckoutAuth: false, header: false },
				},
				{
					path: 'resumo',
					name: 'resumo',
					component: () => import('@ebradi/pages/Resumo'),
					meta: { requiresCheckoutAuth: false, header: false },
				},
				{
					path: 'cadastrar_senha',
					name: 'cadastrar.senha',
					component: () => import('@ebradi/pages/CadastrarSenha'),
					meta: { requiresCheckoutAuth: false, header: true },
				},
			]
		}
	];
}

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes: [
		{
			path: '/',
			component: () => import('@pages/Home'),
			meta: { requiresAuth: true },
			children: [
				{
					path: '/',
					name: 'home',
					component: () => import('@pages/portal/Home'),
					meta: { requiresAuth: true }
				},
				{
					path: '/styleguide',
					name: 'styleguide',
					component: () => import('@pages/portal/Styleguide'),
					meta: { requiresAuth: true }
				},
				{
					path: '/dados-pessoais',
					name: 'dados-pessoais',
					component: () => import('@pages/portal/DadosPessoais'),
					meta: { requiresAuth: true }
				},
				{
					path: '/meus-pagamentos',
					name: 'meus-pagamentos',
					component: () => import('@pages/portal/MeusPagamentos'),
					meta: { requiresAuth: true }
				},
				{
					path: '/experiencias',
					name: 'experiencias',
					component: () => import('@pages/portal/Experiencias'),
					meta: { requiresAuth: true }
				},
				{
					path: '/pagamento-cartao',
					name: 'pagamento-cartao',
					component: () => import('@pages/portal/PagamentoCartao'),
					meta: { requiresAuth: true }
				},
				{
					path: '/envio-documentos',
					name: 'envio-documentos',
					component: () => import('@pages/portal/EnvioDocumentos'),
					meta: { requiresAuth: true }
				},
				{
					path: '/atividades-complementares',
					name: 'atividades-complementares',
					component: () => import('@pages/portal/AtividadesComplementares'),
					meta: { requiresAuth: true }
				},
				{
					path: '/certificado-pos-digital',
					name: 'certificado-pos-digital',
					component: () => import('@pages/portal/CertificadoPosDigital'),
					meta: { requiresAuth: true }
				},
				{
					path: '/solicitacoes',
					name: 'solicitacoes',
					component: () => import('@pages/portal/Solicitacoes'),
					meta: { requiresAuth: true }
				},
				{
					path: '/estagio-obrigatorio',
					name: 'estagio-obrigatorio',
					component: () => import('@pages/portal/EstagioObrigatorio'),
					meta: { requiresAuth: true }
				},
			]
		},
		{
				path: '/status/msfinanceiro',
				name: 'status.msfinanceiro',
				component: () => import('@pages/portal/StatusMSFinanceiro'),
				meta: { requiresAuth: false, layout: 'empty' }
		},
		{
			path: '/login',
			component: () => import('@pages/login/Login'),
			meta: { requiresAuth: false },
			children:[
				{
					path: '/login',
					name: 'login.home',
					component: () => import('@pages/login/LoginHome'),
					alias: '/login/matricula',
					meta: { requiresAuth: false }
				},
				{
					path: '/login/logoff',
					name: 'login.logoff',
					component: () => import('@pages/login/LoginLogoff'),
					meta: { requiresAuth: true }
				},
				{
					path: '/login/remember',
					name: 'login.remember',
					component: () => import('@pages/login/LoginRemember'),
					meta: { requiresAuth: false }
				},
				{
					path: '/login/sign-up',
					name: 'login.signup',
					component: () => import('@pages/login/LoginSignup'),
					meta: { requiresAuth: false }
				},
				{
					path: '/login/success',
					name: 'login.success',
					component: () => import('@pages/login/LoginSuccess'),
					meta: { requiresAuth: false }
				},
				{
					path: '/login/recover',
					name: 'login.recover',
					component: () => import('@pages/login/recover/LoginRecover'),
					meta: { requiresAuth: false }
				},
				{
					path: '/login/recover/email',
					name: 'login.email_code',
					component: () => import('@pages/login/recover/LoginEmailCode'),
					meta: { requiresAuth: false }
				},
				{
					path: '/login/recover/newpass',
					name: 'login.new_pass',
					component: () => import('@pages/login/recover/LoginNewPass'),
					meta: { requiresAuth: false }
				},
				{
					path: '/login/recover',
					name: 'login.recover_methods',
					component: () => import('@pages/login/recover/LoginRecoverMethod'),
					meta: { requiresAuth: false }
				},
				{
					path: '/login/recover/success',
					name: 'login.recover_success',
					component: () => import('@pages/login/recover/LoginRecoverSuccess'),
					meta: { requiresAuth: false }
				},
			]
		},
		...routerEBRADI,
		{
			path: '*',
			component: () => import('@pages/NotFound'),
			meta: { requiresAuth: false, header: true }
		}
	],
	scrollBehavior(_to, _from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
  },
});

router.beforeEach((to, from, next) => {
	const routeRequiresAuth = to.matched.some(record => record.meta.requiresAuth);
	const routeRequiresCheckoutAuth = to.matched.some(record => record.meta.requiresCheckoutAuth);
	const isLogged = router.app.$store.getters['sessao/isLogged'];
	const isCheckoutLogged = router.app.$store.getters['sessao/isCheckoutLogged'];
	const redirect = to.meta?.redirect ?? 'login.home'

	if ((routeRequiresAuth && !isLogged) || (routeRequiresCheckoutAuth && !isCheckoutLogged)) {
		return next({name: redirect});
	}

	if ((!routeRequiresAuth && isLogged && ie !== 'ebr') || (!routeRequiresCheckoutAuth && isCheckoutLogged && ie !== 'ebr')) {
		return next({name: 'home'});
	}

	if (to.name === 'certificado-pos-digital') {
		if (from.name !== 'home') {
			return next({name: 'home'});
		}

		router.app.$store.commit('aluno/certificados/openModal', true);
		return;
	}

	if (to.name === 'atividades-complementares') {
		let vinculo = router.app.$store.getters['vinculosAcademicos/getVinculoSelecionado'];
		if(!isPlainObject(vinculo) ||  vinculo.nens_id == 2) {
			return next({name: 'home'});
		}

		return next();
	}

	return next();
});

export default router;
