<template>
	<div 
		v-if="active"
		class="modal-info-status"
	>
		<div class="modal-info-status-body">
			<Card :color="color" :background="background">
				<slot />
			</Card>
		</div>
	</div>
</template>

<script>
import Card from '@ebradi/componets/Card'

export default {
	name: 'ModalComponentEBRADI',
	components: {
		Card
	},
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		color: {
			type: String,
			required: false,
			default: 'primary'
		},
		background: {
			type: String,
			required: false,
			default: 'block'
		}
	},
	data() {
		return {
			active: false
		}
	},
	watch: {
		value(value) {
			this.active = value
		},
		active(value) {
			this.$emit('input', value)
		}
	},
	mounted() {
		this.active = this.value
	}
}
</script>

<style lang="scss" scoped>
.modal-info-status {
	background: rgba(0, 0, 0, 0.8);
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	opacity: 0;
	animation: opacity forwards 0.4s;
	z-index: 998;
		
	&-body {
		margin: 42px 16px;
		width: 100%;
		max-width: 720px;
	}
}

.text-color-muted {
	color: #B2B2B2;
}

.text-danger {
	color: #C93F58;
}

.text-cyan-500 {
	color: #388BA2
}

.text-secondary {
	color: var(--secondary-400);
}

@keyframes opacity {
	from { 
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
</style>
