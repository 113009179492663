import http from '@commons/http';
import { noConcurrency } from '@commons/request';
import { validateToken } from './commons';
import { setRefreshToken, setRefreshTokenOffice } from '@commons/authenticate'

const actions = {
	async authCheckout({ commit }, captcha) {
		const data = (await http.post('/v1/auth/Login', null, {
			'x-apisecretkey': 'zLtohUbro2l3ruwrlq10',
			'x-captcha': captcha,
		}, 'json', true)).data;

		commit('setCheckoutToken', {
			token: data.token,
			refreshToken: data.refreshToken,
		});

		commit('setCheckoutVerified', true);
	},

	async logoffCheckout({ commit }) {
		commit('setCheckoutToken', {
			token: null,
			refreshToken: null,
		});
	},

	async login({ commit, dispatch }, loginData) {
		const data = (await http.post('/v1/auth/Login', loginData)).data;

		commit('setToken', {
			token: data.token,
			refreshToken: data.refreshToken,
		});

		setRefreshToken(data.refreshToken);

		dispatch('setData', data);

		commit('setPermiteVerModalTermos', data.permite_ver_modal_termos);

		commit('setVerified', true);
	},

	async update({ commit, dispatch }) {
		const data = (await http.get('/v1/aluno/atualiza-dados')).data;

		dispatch('setData', data);

		commit('setPermiteVerModalTermos', data.permite_ver_modal_termos);

		commit('setVerified', true);
	},

	setData({ commit, rootState  }, data) {
		const modifiedCursos = data.cursos.length != rootState.cursos.cursos.length
			|| rootState.cursos.cursos.some((curso, index) => [
				'ativo',
				'condicao_curso',
				'curs_id',
				'curs_nome',
				'habi_id',
				'nens_id',
				'nens_nome',
				'status_descricao',
				'turn_id',
				'viac_sequencia',
			].some(field => curso[field] != data.cursos[index][field]));

		if (modifiedCursos) {
			commit('cursos/setCursos', data.cursos, { root: true });
			commit('vinculosAcademicos/setVinculos', data.cursos, { root: true });
			const curso = data.cursos[data.cursos.length - 1];
			if (data.nens_id) {
				commit('setNivelDeEnsino', data.nens_id, { root: true });
			} else {
				commit('setNivelDeEnsino', curso.nens_id, { root: true });
			}
		}

		if (rootState.aceiteTermoDocumentos != data.aceitou_termo_documentos) {
			commit('setAceiteTermoDocumentos', data.aceitou_termo_documentos, { root: true });
		}

		if (rootState.visualizouModalExperiencias != data.experiencias_modal) {
			commit('experiencias/setVisualizouModalExperiencias', data.experiencias_modal, { root: true });
		}

		if (rootState.alunoMatricula != data.alun_matricula) {
			commit('setAlunoMatricula', data.alun_matricula, { root: true });
		}

		if (rootState.alunoCidade != data.cida_nome) {
			commit('setAlunoCidade', data.cida_nome, { root: true });
		}

		if (rootState.alunoCPF != data.pess_cpf) {
			commit('setAlunoCPF', data.pess_cpf, { root: true });
		}

		if (rootState.alunoDataNascimento != data.pess_data_nascimento) {
			commit('setAlunoDataNascimento', data.pess_data_nascimento, { root: true });
		}

		if (rootState.aluno.dadosPessoais.nome != data.pess_nome) {
			commit('aluno/dadosPessoais/setNome', data.pess_nome, { root: true });
		}

		if (rootState.aluno.dadosPessoais.nomeSocial != data.pess_nome_social) {
			commit('aluno/dadosPessoais/setNomeSocial', data.pess_nome_social, { root: true });
		}

		if (rootState.calouro != data.calouro) {
			commit('setCalouro', data.calouro, { root: true });
		}

		if (rootState.periodo != data.periodo) {
			commit('setPeriodo', data.periodo, { root: true });
		}

		if (rootState.cursosNaoAceitos != data.cursos_nao_aceitos) {
			commit('setCursosNaoAceitos', data.cursos_nao_aceitos, { root: true });
		}

		if (rootState.vinculosAcademicos.aceiteTermosFiltro != data.aceite_filtro_vinculo) {
			commit('vinculosAcademicos/setAceiteTermosFiltro', data.aceite_filtro_vinculo, { root: true });
		}

		commit('setBloqueioDisciplinas', data.bloqueio);

	},

	refreshToken: noConcurrency(async ({ state, commit, dispatch }) => {
		const minimumAge = new Date();
		minimumAge.setSeconds(-30);

		if (
			state.tokenRequestTime
			&& state.tokenRequestTime >= minimumAge
			&& state.token
		) {
			return state.token;
		}

		try {
			if (!state.refreshToken) {
				throw new Error('Invalid refresh token');
			}

			const data = (await http.post(
				'/v1/auth/refresh',
				'refreshToken=' + state.refreshToken
			))
				.data;

			if (!validateToken(data?.token)) {
				throw Error('Invalid Token');
			}

			commit('setToken', {
				token: data.token,
				refreshToken: data.refreshToken,
			});

			return data.token;
		} catch (error) {
			await dispatch('logoff');

			throw error;
		}
	}),

	async logoff({ commit }) {
		commit('setToken', {
			token: null,
			refreshToken: null,
		});

		await Promise.all([
			http.post('/v1/auth/Logout'),
			http.get('/ajax/sessao/logoff.php')
		]);
	},

	async loginOffice({ commit, dispatch }, loginData) {
		const data = (await http.post('/v1/auth/LoginOffice', loginData)).data;

		commit('setToken', {
			token: data.token,
			refreshToken: data.refreshToken,
		});

		setRefreshTokenOffice(data.refreshToken);

		dispatch('setData', data);
	},
};

export default actions;
