<template>
	<link
		id="style-portal-ebradi"
		rel="stylesheet"
		href="/portal.css"
	/>
</template>

<script>
export default {
	name: 'ThemeEBRADI'
}
</script>
