<template>
	<section class="container">
		<Card
			class="container-card"
			background="block"
		>
			<div class="container-parabens">
				<img src="@ebradi/assets/img/image-4.svg">
				<h2>Parabéns, {{ name }}</h2>
				<p>
					Você concluiu o processo de compra do seu curso, agora você pode seguir direto
					para o
					<a
						target="_blank"
						@click="portalEstudante"
					>
						Portal do Estudante
					</a> criando sua senha
					clicando no botão abaixo.
					<br><br>
					Caso prefira, pode retornar esse processo posteriormente,
					não deixe de conferir seu e-mail com as instruções!
				</p>
				<div class="is-flex pb-5">
					<b-button
						class="m-auto is-secondary"
						rounded
						@click="cadastrarSenha"
					>
						Vamos lá!
					</b-button>
				</div>
			</div>
		</Card>
	</section>
</template>

<script>
import Card from '@ebradi/componets/Card'

export default {
	name: "ParabensPageEBRADI",
	components: {
		Card
	},
	data() {
		return {
			name: this.$route.query.nome
		}
	},
	mounted() {
		setTimeout(() => {
			window.confetti({
                particleCount: 2000,
				ticks: 150,
				gravity: 0,
				decay: 0.94,
				startVelocity: 40,
                spread: 80,
                origin: { y: 1 },
				shapes: ["star"],
				colors: ["AC9169", "33C1D5", "BADFE7"]
            })
		}, 500)
	},
	methods: {
		portalEstudante() {
			location.href = '/';
		},
		cadastrarSenha() {
			this.$router.push({
				name: 'cadastrar.senha',
				query: {
					alun_token: this.$route.query.alun_token,
				},
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.confetti {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 999;
	width: 100%;
	height: 100vh;
	display: none;

	&.active {
		display: block;
	}
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 82px;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.container-card {
	width: 100%;
	max-width: 775px;
}

.container-parabens {
	padding: 20px 92px;
	text-align: center;

	img {
		margin: 42px 0;
	}

	h2 {
		font-size: 28px;
		line-height: 32px;
		font-weight: 900;
	}

	p {
		margin: 42px 0;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}

}

</style>
