export const MetodoPagamento = Object.freeze({
	GRATUITO: '0',
	PIX: '5',
	BOLETO_RECORRENTE: '2',
	BOLETO_A_VISTA: '1',
	CARTAO_DE_CREDITO_RECORRENTE: '4',
	CARTAO_DE_CREDITO_A_VISTA: '3',
});

export const MetodoPagamentoInfos = Object.freeze({
	[MetodoPagamento.PIX]: {
    label: 'Pix',
    icon: require('@ebradi/assets/img/icon-pix.svg'),
  },
	[MetodoPagamento.BOLETO_RECORRENTE]: {
    label: 'Boleto recorrente',
    icon: require('@ebradi/assets/img/icon-code-bar.svg'),
  },
	[MetodoPagamento.BOLETO_A_VISTA]: {
    label: 'Boleto à vista',
    icon: require('@ebradi/assets/img/icon-code-bar.svg'),
  },
	[MetodoPagamento.CARTAO_DE_CREDITO_RECORRENTE]: {
    label: 'Cartão recorrente',
    icon: require('@ebradi/assets/img/icon-card.svg'),
  },
	[MetodoPagamento.CARTAO_DE_CREDITO_A_VISTA]: {
    label: 'Cartão de crédito',
    icon: require('@ebradi/assets/img/icon-card.svg'),
  },
});

export const metodoPagamentoAtivo = [
	MetodoPagamento.BOLETO_A_VISTA,
	MetodoPagamento.BOLETO_RECORRENTE,
	MetodoPagamento.CARTAO_DE_CREDITO_A_VISTA,
	MetodoPagamento.CARTAO_DE_CREDITO_RECORRENTE,
];

export const metodoPagamentoComParcelamento = [
	MetodoPagamento.BOLETO_A_VISTA,
	MetodoPagamento.BOLETO_RECORRENTE,
	MetodoPagamento.CARTAO_DE_CREDITO_A_VISTA,
	MetodoPagamento.CARTAO_DE_CREDITO_RECORRENTE,
];

export const metodoPagamentoComVencimento = [
	MetodoPagamento.BOLETO_RECORRENTE,
	MetodoPagamento.CARTAO_DE_CREDITO_RECORRENTE,
];


export default {
	MetodoPagamento,
	MetodoPagamentoInfos,
	metodoPagamentoAtivo,
	metodoPagamentoComParcelamento,
	metodoPagamentoComVencimento,
};
