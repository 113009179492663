import { render, staticRenderFns } from "./FormEndereco.vue?vue&type=template&id=5359c774&scoped=true&"
import script from "./FormEndereco.vue?vue&type=script&lang=js&"
export * from "./FormEndereco.vue?vue&type=script&lang=js&"
import style0 from "./FormEndereco.vue?vue&type=style&index=0&id=5359c774&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5359c774",
  null
  
)

export default component.exports