<template>
	<section class="container-cadastro-senha">
		<Card background="block" :loading="loading">
			<div>
				<div class="header-info">
					<h2>
						Seja bem-vindo ao <br><span class="text-active">Portal do Estudante!</span>
					</h2>
					<h5>Cadastre uma senha para continuar</h5>
				</div>
				<div class="inputs-password">
					<div>
						<UIInput
							v-model="form.password"
							id="senha"
							type="password"
							label="Senha"
						/>
						<UIInput
							v-model="form.password_confirmation"
							id="senha"
							type="password"
							class="mt-5"
							label="Confirme sua senha"
						/>
					</div>
				</div>
				<div class="info-senha">
					<div>
						<span>Sua senha deve ter:</span>
						<ul>
							<li :class="{ 'strike': same }">Confirmação da senha</li>
							<li :class="{ 'strike': min }">Mínimo de 8 caracteres</li>
							<li :class="{ 'strike': uppercase }">1 letra maiúscula</li>
							<li :class="{ 'strike': lowercase }">1 letra minúscula</li>
							<li :class="{ 'strike': number }">1 número</li>
							<li :class="{ 'strike': special }">1 caractere especial</li>
						</ul>
					</div>
				</div>
				<div class="btns-group">
					<b-button
						type="is-secondary"
						rounded
						@click="submit"
						:disabled="!same || !min || !lowercase || !uppercase || !number || !special"
					>
						Próximo
					</b-button>
				</div>
			</div>
		</Card>
		<Recaptcha ref="grecaptcha" />
		<LoadingPage v-model="loading" />
	</section>
</template>

<script>
import '@commons/rules.js'

import Card from '@ebradi/componets/Card';
import LoadingPage from '@ebradi/componets/LoadingPage';
import Recaptcha from '@components/Recaptcha';
import UIInput from '@components/uiinput';

export default {
	name: "CadastrarSenhaPageEBRADI",
	components: {
		Card,
		UIInput,
		LoadingPage,
		Recaptcha,
	},
	data() {
		return {
			alun_token: '',
			loading: false,
			form: {
				captcha: '',
				password: '',
				password_confirmation: '',
			},
		}
	},
	mounted() {
		if (!window.grecaptcha) {
			if (!this.form.captcha) {
				window.addEventListener('click', this.listener);
			}

			const recaptchaScript = document.createElement('script');
			recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=explicit');
			document.head.appendChild(recaptchaScript);
			recaptchaScript.onload = () => {
				window.grecaptcha.ready(async () => {
					this.form.captcha = await this.$refs.grecaptcha.generate();
				});
			};
    }
	},
	methods: {
		async listener() {
			if (!this.form.captcha) {
				this.form.captcha = await this.$refs.grecaptcha.generate();
			} else {
				window.removeEventListener('click', this.listener);
			}
		},
		async submit() {
			this.loading = true;
			if (!window.grecaptcha) {
				this.toastOpen('Recaptcha inválido!', 'is-danger');
				this.loading = false;
				return;
			}

			this.$store
				.dispatch('ebradi/cadastrarSenha', {
					senha: this.form.password,
					alun_token: this.$route.query.alun_token,
					captcha: this.form.captcha,
				})
				.then(() => {
					location.href = '/';
				})
				.catch((res) => {
					console.log(res);
					this.toastOpen('Erro ao cadastrar nova senha', 'is-danger');
				})
				.finally(() => {
					this.loading = false;
				});
		},
		toastOpen(message, type) {
			this.$buefy.toast.open({
				message,
				type,
				duration: 4000,
			});
		},
	},
	computed: {
		same() {
			return this.form.password.length && this.form.password === this.form.password_confirmation;
		},
		min() {
			return /^.{8,}$/.test(this.form.password);
		},
		lowercase() {
			return /.*[a-z].*/.test(this.form.password);
		},
		uppercase() {
			return /.*[A-Z].*/.test(this.form.password);
		},
		number() {
			return /.*\d.*/.test(this.form.password);
		},
		special() {
			return /.*[!@#$%^&*(),.?":{}|<>].*/.test(this.form.password);
		},
	},
}
</script>

<style lang="scss" scoped>
.container-cadastro-senha {
	margin: auto;
	max-width: 704px!important;

	&::v-deep {
		.ui-input, .control  {
			background: transparent!important;
			border: none!important;
		}

		.ui-input > label {
			position: static!important;
			color: var(--grey);
			margin: 0px!important;
			font-size: 16px!important;
			line-height: 24px!important;
			margin-bottom: 4px!important;
			font-weight: 400!important;
		}

		.input {
			border-radius: 12px!important;
			color: var(--grey)!important;
			border: solid 1px #777777!important;
			padding: 4px 16px!important;

			&::placeholder {
				color: #555!important;
			}

			&::-ms-input-placeholder {
				color: #555!important;
			}
		}

		.control.has-icons-right {
			overflow: inherit!important;
		}

		.control.has-icons-right > .input {
			width: calc(100%)!important;
		}

		.control > .icon.is-right {
			position: absolute!important;
			bottom: initial!important;
			top: initial!important;
			right: -48px!important;
			color: var(--grey)!important;
			height: 48px!important;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 1;
		}

		.control > .icon.is-right > .icon {
			height: 48px!important;
		}
	}
}

.header-info {
	color: #F1F9FA;
	text-align: center;
	font-size: 32px;
	line-height: 40px;
	font-weight: 700;
	padding: 44px;
	padding-bottom: 0px;

	h5 {
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
		margin-top: 44px;
		color: #DAEFF3;
	}
}

.inputs-password {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 32px 0px;

	& > div {
		max-width: 310px;
		width: 100%;
	}
}

.text-active {
	color: var(--primary);
}

.info-senha {
	font-weight: 400;
	font-size: 12px;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;

	& > div {
		width: 100%;
		max-width: 310px;
	}

	ul {
		list-style: outside;
		padding-left: 26px;
	}
}

.btns-group {
	display: flex;
	align-items: center;
	margin: 44px 0;
	padding-bottom: 44px;
	justify-content: center;
	width: 100;
}

.strike {
	text-decoration: line-through;
}

.inputs-password .control:has(#senha){
	background-color: transparent !important;
}

.inputs-password #senha {
	color: #b2b2b2 !important;
}

.inputs-password .ui-input > .label {
    color: #b2b2b2 !important;
}
</style>
