var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form_dados_responsavel_financeiro"},[_c('form',{staticClass:"form-dados-responsavel-financeiro",on:{"submit":function () { return false; }}},[_c('h2',[_vm._v("Dados do responsável financeiro")]),_c('fieldset',[_c('div',{staticClass:"colunas-inputs"},[_c('div',{staticClass:"coluna"},[_c('ValidationProvider',{attrs:{"name":"Razão Social","rules":"required|max:100|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Razão Social","message":errors[0],"type":errors.length > 0 ? 'is-danger' : ''}},[_c('b-input',{model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}})],1)]}}])})],1),_c('div',{staticClass:"coluna"},[_c('ValidationProvider',{attrs:{"name":"CNPJ","rules":"required|cnpj|max:18|min:18"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"CNPJ","message":errors[0],"type":errors.length > 0 ? 'is-danger' : ''}},[_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:(['##.###.###/####-##']),expression:"['##.###.###/####-##']"}],model:{value:(_vm.form.cnpj),callback:function ($$v) {_vm.$set(_vm.form, "cnpj", $$v)},expression:"form.cnpj"}})],1)]}}])})],1),_c('div',{staticClass:"coluna"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Email","message":errors[0],"type":errors.length > 0 ? 'is-danger' : ''}},[_c('b-input',{attrs:{"type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}])})],1),_c('div',{staticClass:"coluna"},[_c('ValidationProvider',{attrs:{"name":"Inscrição Municipal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Inscrição Municipal","message":errors[0],"type":errors.length > 0 ? 'is-danger' : ''}},[_c('b-input',{model:{value:(_vm.form.municipal_registration),callback:function ($$v) {_vm.$set(_vm.form, "municipal_registration", $$v)},expression:"form.municipal_registration"}})],1)]}}])})],1),_c('div',{staticClass:"coluna"},[_c('ValidationProvider',{attrs:{"name":"Inscrição Estadual","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Inscrição Estadual","message":errors[0],"type":errors.length > 0 ? 'is-danger' : ''}},[_c('b-input',{model:{value:(_vm.form.state_registration),callback:function ($$v) {_vm.$set(_vm.form, "state_registration", $$v)},expression:"form.state_registration"}})],1)]}}])})],1)]),_c('ValidationProvider',{attrs:{"name":"Regime Fiscal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectCheckBox',{attrs:{"options":_vm.optionsRegimeFiscal,"label":"Regime Fiscal","errors":errors,"name":"tax_regime"},model:{value:(_vm.form.tax_regime),callback:function ($$v) {_vm.$set(_vm.form, "tax_regime", $$v)},expression:"form.tax_regime"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Substituto Tributário do ISSQN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CheckBox',{attrs:{"name":"issqn","label":"Substituto Tributário do ISSQN","errors":errors},model:{value:(_vm.form.issqn),callback:function ($$v) {_vm.$set(_vm.form, "issqn", $$v)},expression:"form.issqn"}})]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }